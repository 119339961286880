<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <b-img-lazy center width="150px" height="150px" :style="show_icon.css" rounded
                        :src="show_icon.image"></b-img-lazy>
          </div>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover table-striped table-bordered">
              <tbody>
              <tr>
                <td class=" text-left">ID :</td>
                <td class=" text-right">{{ data.token }}</td>
              </tr>
              <tr>
                <td class=" text-left">Order Id :</td>
                <td class=" text-right">{{ data.orderid }}</td>
              </tr>
              <tr>
                <td class=" text-left">Merchant Name :</td>
                <td class=" text-right">{{ data.customer }}</td>
              </tr>
              <tr>
                <td class=" text-left">Status :</td>
                <td class=" text-right" :style="show_icon.text_css">{{ data.status }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import typebank from "@/util/typebank";

export default {
  name: "DatailBanktransfer",
  props: ['data'],
  computed: {
    datatype_bank() {
      return typebank()
    },
    convertPrice() {
      return this.data && this.data.price && !isNaN(this.data.price) ? this.data.price.toFixed(2) : 0.00
    },
    logo_bank() {
      const findIndex = this.datatype_bank.findIndex(value => value.key === this.data.accountbank.type.toLowerCase())
      return {
        image: require(`../../assets/iconbank/${this.data.accountbank.type.toLowerCase()}.svg`),
        css: {
          'background-color': this.datatype_bank[findIndex].color,
          'margin-bottom': '20px'
        }
      }
    },
    show_icon() {
      return {
        image: require(`../../assets/icon/success.svg`),
        css: {
          'margin': '50px'
        },
        text_css: {
          'color': 'green',
          'font-size': '24px'
        }
      }
    },
    copy_icon() {
      return {
        image: require(`../../assets/icon/copy.svg`),
        css: {
          'margin': '5px'
        },
        amount_css: {
          'color': 'green',
          'font-size': '24px'
        }
      }
    },
    description() {
      return {
        text: 'หมายเหตุ : กรุณาตรวจสอบรายละเอียดการโอนเงิน และโอนเงินตามหมายเลขบัญชีรับเงินที่แสดงด้านบน',
        css: {
          'color': 'red',
          'text-align': 'center'
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
