<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <h3 class="text-center" style="margin: 50px 0px;">
          บัญชีรับเงิน
        </h3>
        <div class="row">
          <div class="col-md-5">
            <b-img-lazy center width="250px" height="250px" :style="logo_bank.css" rounded
                        :src="logo_bank.image"></b-img-lazy>
          </div>
          <div class="col-md-7 table-responsive">
            <table class="table table-hover table-striped table-bordered">
              <tbody>
              <tr>
                <td class=" text-left">ID :</td>
                <td class=" text-right">{{ data.token }}</td>
              </tr>
              <tr>
                <td class=" text-left">Order ID :</td>
                <td class=" text-right">{{ data.orderid }}</td>
              </tr>
              <tr>
                <td class=" text-left">Merchant :</td>
                <td class=" text-right">{{ data.customer }}</td>
              </tr>
              <tr>
                <td class=" text-left">ชื่อบัญชี :</td>
                <td class=" text-right">{{ data.accountbank.name }}</td>
              </tr>
              <tr>
                <td class=" text-left">ชื่อธนาคาร :</td>
                <td class=" text-right">{{ data.accountbank.type }}</td>
              </tr>
              <tr>
                <td class=" text-left">เลขบัญชี :</td>
                <td class=" text-right">{{ data.accountbank.account }} <a href="#"
                                                                          v-clipboard:copy="data.accountbank.account">
                  <b-img-lazy right width="25px" height="25px" :style="copy_icon.css" rounded :src="copy_icon.image">
                  </b-img-lazy>
                </a></td>
              </tr>
              <tr>
                <td class=" text-left">ยอดเงินฝาก (THB) :</td>
                <td class=" text-right" :style="copy_icon.amount_css"><strong>{{ convertPrice }} THB</strong></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-12" :style="description.css">
        {{ description.text }}
      </div>
    </div>
  </div>
</template>

<script>
import typebank from "@/util/typebank";

export default {
  name: "DatailBanktransfer",
  props: ['data'],
  computed: {
    datatype_bank() {
      return typebank()
    },
    convertPrice() {
      return this.data && this.data.price && !isNaN(this.data.price) ? this.data.price.toFixed(2) : 0.00
    },
    logo_bank() {
      const findIndex = this.datatype_bank.findIndex(value => value.key === this.data.accountbank.type.toLowerCase())
      return {
        image: require(`../../assets/iconbank/${this.data.accountbank.type.toLowerCase()}.svg`),
        css: {
          'background-color': this.datatype_bank[findIndex].color,
          'margin-bottom': '20px'
        }
      }
    },
    copy_icon() {
      return {
        image: require(`../../assets/icon/copy.svg`),
        css: {
          'margin': '5px'
        },
        amount_css: {
          'color': 'green',
          'font-size': '24px'
        }
      }
    },
    description() {
      return {
        text: 'หมายเหตุ : กรุณาตรวจสอบรายละเอียดการโอนเงิน และโอนเงินตามหมายเลขบัญชีรับเงินที่แสดงด้านบน',
        css: {
          'color': 'red',
          'text-align': 'center'
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
