<template>
  <div id="BT">
    <b-container>
      <b-card header="รายละเอียด">
        <div v-if="loading">
          <div class="loader text-center"></div>
        </div>
        <div v-else-if="checkVerify">
          <div>
            <b-form @submit="submitUpdate" @reset="onReset">
              <b-form-group label="Bank:" description="Your bank transfer">
                <b-form-select v-model="formUpdate.bank" :options="covertTypeBank"></b-form-select>
              </b-form-group>
              <b-form-group label="Account Number:" description="Account number your bank transfer">
                <b-form-input v-model="formUpdate.bank_account" type="text" placeholder="0123456789" required>
                </b-form-input>
              </b-form-group>
              <b-form-group label="Name:" description="Account Name your bank transfer.">
                <b-form-input v-model="formUpdate.bank_name" type="text" placeholder="FristName LastName" required>
                </b-form-input>
              </b-form-group>
              <b-button type="submit" variant="primary">Submit</b-button>
              <b-button type="reset" variant="danger">Reset</b-button>
            </b-form>
          </div>
        </div>
        <div v-else>
          <div v-if="detail.status === 'wait'">
            <QRcode v-if="detail.qrcode" :data="detail" class="text-center"/>
            <Banktransfer v-else :data="detail" class="text-center"/>
          </div>
          <div v-else>
            <div v-if="detail.status === 'cancel'">
              <Cancel :data="detail" class="text-center"/>
            </div>
            <div v-if="detail.status === 'success'" class="row">
              <Success :data="detail" class="text-center"/>
            </div>
          </div>
          <div v-if="detail.status === 'wait'" class="commentMessage">
            * {{ messageComment }}
          </div>
        </div>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import QRcode from '../../components/banktransfer/qrcode'
import Banktransfer from '../../components/banktransfer/banktransfer'
import Success from '../../components/banktransfer/success'
import Cancel from '../../components/banktransfer/cancel';
import typebank from "@/util/typebank";

export default {
  name: "DetailBanktransfer",
  components: {
    QRcode,
    Banktransfer,
    Success,
    Cancel
  },
  beforeMount() {
    const query = this.$route.query
    if (query.token) {
      this.getData(query.token)
      this.token = query.token
    } else {
      this.$swal('Not have token', '', 'error')
    }
  },
  computed: {
    messageComment() {
      return this.detail.qrcode ? 'Please use mobile banking, scan the QRcode for payment.' : 'Please transfer  to the account in the details above for payment.Please transfer  to the account in the details above for payment.'
    },
    convertPrice() {
      this.detail.price = this.detail.price * 1
      //alert(this.detail && this.detail.price && !isNaN(this.detail.price) ? this.detail.price.toFixed(2) : 0.00)
      return this.detail && this.detail.price && !isNaN(this.detail.price) ? this.detail.price.toFixed(2) : 0.00
    },
    checkVerify() {
      //alert(this.detail && this.detail.verify_identity ? (!this.detail.from_bank || !this.detail.from_bank_name || !this.detail.from_bank_account) ? true : false : false)
      return this.detail && this.detail.verify_identity ? (!this.detail.from_bank || !this.detail.from_bank_name || !this.detail.from_bank_account) ? true : false : false
    },
    covertTypeBank() {
      return typebank().map(value => {
        return {
          value: value.key.toUpperCase(),
          text: value.name
        }
      })
    }
  },
  sockets: {
    update_deposit(data) {
      const query = this.$route.query
      if (query.token && data.token === query.token) {
        this.detail.status = data.status
        setTimeout(() => this.returnToPage(), 3000)
      }
    }
  },
  data() {
    return {
      token: '',
      loading: true,
      formUpdate: {
        bank: null,
        bank_name: '',
        bank_account: ''
      },
      detail: {
        token: '',
        orderid: '',
        customer: '',
        status: 'cancel',
        image_qrcode: '',
        price: 3044.61,
        qrcode: null,
        verify_identity: false,
        return_page: null,
        from_bank: null,
        from_bank_account: null,
        from_bank_name: null,
        accountbank: {
          account: '7512896302',
          name: '',
          type: 'KBANK'
        }
      },
    }
  },
  methods: {
    async getData(token) {
      this.loading = true
      try {

        const Result = await this.axios.get(`/paymentpage?token=${token}`)
        // const Result = await this.axios.get(`http://demo3009405.mockable.io/paymentpage`)
        // const Result = await this.axios.get(`https://api.fund-pay.dev/paymentpage?token=${token}`)

        if (Result.data) {
          this.detail = Result.data
          //console.log(Result.data);
          if (this.detail.from_bank || this.detail.from_bank_name) {
            // alert( Result.data.from_bank_name)
            this.formUpdate.bank = this.detail.from_bank
            this.formUpdate.bank_name = this.detail.from_bank_name
            this.formUpdate.bank_account = this.detail.from_bank_account
            // alert(this.formUpdate.bank)
          }
          console.log("detail: " + this.detail.status)
          this.loading = false
          setTimeout(() => this.returnToPage(), 3000)
        }
      } catch (e) {
        this.$swal('Error', 'error request api', 'error')
      }
    },
    async submitUpdate(event) {
      event.preventDefault()
      this.loading = true
      try {
        const Result = await this.axios.patch(`/paymentpage/update?token=${this.token}`, this.formUpdate)
        // const Result = await this.axios.patch(`http://demo3009405.mockable.io/paymentpage`)
        // const Result = await this.axios.patch(`https://api.fund-pay.dev/paymentpage/update?token=${this.token}`, this.formUpdate)
        console.log("submitUpdate : " + Result)
        if (Result.data) {
          this.loading = false
          this.getData(this.token)
        }
      } catch (e) {
        this.$swal('Error', 'error request api', 'error')
      }
    },
    returnToPage() {

      if (this.detail.status !== 'wait' && this.detail.return_page) {
        window.location.href = this.detail.return_page
      }
    },
    onReset(event) {
      event.preventDefault()
      this.formUpdate = {
        bank: null,
        bank_name: '',
        bank_account: ''
      }
    }
  }
}
</script>

<style scoped>

.commentMessage {
  color: red;
  text-align: center;
}

.loader {
  margin: auto;
  text-align: center;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px;
  /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
