<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <h3 class="text-center" style="margin: 50px 0px;">
          บัญชีรับเงิน
        </h3>
        <div class="row">
          <div class="col-md-5">
            <b-img-lazy center width="250px" height="250px" rounded :src="data.image_qrcode"></b-img-lazy>
          </div>
          <div class="col-md-7 table-responsive">
            <table class="table table-hover table-striped table-bordered">
              <tbody>
              <tr>
                <td class=" text-left">ID :</td>
                <td class=" text-right">{{ data.token }}</td>
              </tr>
              <tr>
                <td class=" text-left">Order ID :</td>
                <td class=" text-right">{{ data.orderid }}</td>
              </tr>
              <tr>
                <td class=" text-left">Merchant :</td>
                <td class=" text-right">{{ data.customer }}</td>
              </tr>
              <tr>
                <td class=" text-left">ชื่อบัญชี :</td>
                <td class=" text-right">{{ data.accountbank.name }}</td>
              </tr>
              <tr>
                <td class=" text-left">ยอดเงินฝาก (THB) :</td>
                <td class=" text-right" :style="amounts.css"><strong>{{ convertPrice }} THB</strong></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import typebank from "@/util/typebank";

export default {
  name: "DatailBanktransfer",
  props: ['data'],
  computed: {
    datatype_bank() {
      return typebank()
    },
    convertPrice() {
      return this.data && this.data.price && !isNaN(this.data.price) ? this.data.price.toFixed(2) : 0.00
    },
    amounts() {
      return {
        css: {
          'color': 'green',
          'font-size': '24px'
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
